// src/globals.js

// main search
window.mainSearch = {
  currentWorkInfo: {
    mb_8: '',  // 국가직 or 지방직
    mb_17: '', // 행정처
    mb_9: '',  // 광역시도
    mb_10: '', // 시군구
    mb_1: '',  // 직렬
    mb_2: '',  // 직급
    mb_39: '', // 시간선택제
    mb_27: '', // 일방전출    
  },
  hopeWorkInfo: {
    mb_8: '',  // 국가직 or 지방직
    mb_17: '', // 행정처
    mb_9: '',  // 광역시도
    mb_10: '', // 시군구    
  },
  config: {}
}

// main
window.main = {
  applyCount: 0,
  searchApplyCount: 0,
  applyList: [],
  query: {
    page: 1
  }
}