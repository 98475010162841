<template>
  <div class="dolligo-wrap">
    <div class="layer-wrap">
      <header class="fixed">
        <!-- <a
          href="javascript:void(0)"
          onclick="history.back()"
          class="icon close-btn"
          >닫기</a
        > -->
        <router-link
          :to="{ name: `dolligoMain` }"
          class="icon close-btn"
        >
          닫기
        </router-link>
        <div class="right-btns">
          <a
            href="javascript:void(0);"
            v-on:click="initSearch()"
            class="txt-btn"
            :class="{ on: hasAnyValue() }"
            >검색 초기화</a
          >
        </div>
      </header>
      <div class="content-wrap">
        <div class="write-wrap">
          <div class="list-wrap">
            <router-link
              :to="{ path: `/dolligo/main/search/recent` }"
              class="submit-btn on"
            >
              <span class="icon"></span>최근검색 목록
            </router-link>
          </div>

          <div class="list-wrap"></div>
          <div class="list-wrap">
            <h2>교류자의 현재 근무지</h2>
            <ul>
              <depart
                :departCode="currentWorkInfo"
                :isShowNoSelect="false"
                @change.native="search()"
              ></depart>
              <office
                v-if="currentWorkInfo.mb_8 == '국가직'"
                v-model="currentWorkInfo.mb_17"
                :officeCode="currentWorkInfo.mb_17"
                @change.native="search()"
              ></office>
              <addr
                :addrCode="currentWorkInfo"
                @change.native="search()"
              ></addr>
            </ul>
          </div>
          <div class="list-wrap">
            <h2>교류자의 희망 근무지</h2>
            <ul>
              <depart
                :departCode="hopeWorkInfo"
                :isShowNoSelect="false"
                @change.native="search()"
              ></depart>
              <office
                v-if="hopeWorkInfo.mb_8 == '국가직'"
                v-model="hopeWorkInfo.mb_17"
                :officeCode="hopeWorkInfo.mb_17"
                @change.native="search()"
              ></office>
              <addr :addrCode="hopeWorkInfo" @change.native="search()"></addr>
            </ul>
          </div>

          <div class="list-wrap">
            <h2>교류자의 직렬, 직급</h2>
            <ul>
              <job-rank
                v-model="currentWorkInfo"
                :jobRankCode="currentWorkInfo"
                @change.native="search()"
              ></job-rank>
            </ul>
            <ul>
              <li>
                <div class="check-wrap match">
                  <label for="time" class="form-check-label">
                    <input
                      id="time"
                      type="checkbox"
                      class="form-check-input"
                      v-model="currentWorkInfo.mb_39"
                      true-value="1"
                      false-value=""
                      @change="search()"
                    />시간선택제 공무원
                    <span class="form-check-sign">
                      <span class="check"> </span>
                    </span>
                  </label>
                </div>
              </li>
            </ul>
          </div>

          <div class="list-wrap">
            <h2>상세 조건 추가<span class="icon premium-i"></span></h2>
            <ul>
              <li>
                <label
                  class="submit-btn"
                  :class="{ on: currentWorkInfo.mb_27 == '1' }"
                  ><input
                    type="checkbox"
                    true-value="1"
                    false-value=""
                    v-model="currentWorkInfo.mb_27"
                    style="-webkit-appearance: none; position: relative"
                    v-on:change="
                      checkPremium();
                      search();
                    "
                  />
                  일방 전출
                </label>
              </li>
            </ul>
          </div>
          <button
            class="submit-btn on fixed"
            id="btn-submit"
            v-on:click="goApplyList()"
          >
            {{ resuleMessage }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Addr from "../../common/Addr.vue";
import Depart from "../../common/Depart.vue";
import JobRank from "../../common/JobRank.vue";
import Office from "../../common/Office.vue";
import axios from "axios";

export default {
  components: {
    Depart,
    Office,
    Addr,
    JobRank,
  },
  name: "dolligoMainSearch",
  data: function () {
    return {
      currentWorkInfo: { ...window.mainSearch.currentWorkInfo },
      hopeWorkInfo: { ...window.mainSearch.hopeWorkInfo },
      resuleMessage: "검색하기",
      applyData: null,
      config: { ...window.mainSearch.config },
      STORAGE_KEY: "searchHistory",
    };
  },
  created: function () {
    this.search();
  },
  methods: {
    checkPremium: function () {
      if (this.MEMBER.mb_level < 5) {
        this.currentWorkInfo.mb_27 = "";
        if (
          confirm("프리미엄 멤버십 회원만 검색 가능합니다\n가입하겠습니까?")
        ) {
          this.$router.push({ name: "dolligoPremiumIntro" });
        }
      }
    },
    search: async function () {
      this.config.params = this.currentWorkInfo;
      this.config.params.hopeCity = this.hopeWorkInfo.mb_9;
      this.config.params.hopeDistrict = this.hopeWorkInfo.mb_10;
      this.config.params.mb_18 = this.hopeWorkInfo.mb_8;
      this.config.params.mb_19 = this.hopeWorkInfo.mb_17;

      let response = await axios.get("/api/apply", this.config);
      this.applyData = response.data;
      this.resuleMessage = `검색 결과 보기(${this.applyData.count.toLocaleString()}건)`;
    },
    goApplyList: async function () {
      // 검색 조건 window 변수에 저장
      window.mainSearch.currentWorkInfo = this.currentWorkInfo;
      window.mainSearch.hopeWorkInfo = this.hopeWorkInfo;
      window.mainSearch.config = this.config;

      // main data 초기화
      window.main.applyCount = 0;
      window.main.searchApplyCount = 0;
      window.main.applyList = [];
      window.main.query.page = 1;

      this.addSearchHistory({
        currentWorkInfo: window.mainSearch.currentWorkInfo,
        hopeWorkInfo: window.mainSearch.hopeWorkInfo,
      });

      this.$router.push({
        name: "dolligoMain",
      });
    },
    initSearch: function () {
      for (let key in this.currentWorkInfo) {
        this.currentWorkInfo[key] = "";
      }
      for (let key in this.hopeWorkInfo) {
        this.hopeWorkInfo[key] = "";
      }
      this.resuleMessage = "검색하기";
      this.applyData = null;
      this.config = {};
    },

    hasAnyValue() {
      // 두 객체의 모든 값을 배열로 합친 후, 빈 값이 아닌 항목이 있는지 확인
      return [
        ...Object.values(this.currentWorkInfo),
        ...Object.values(this.hopeWorkInfo),
      ].some((value) => value !== "");
    },

    // `localStorage`에서 검색 기록을 가져오는 함수
    getSearchHistory() {
      const storedData = localStorage.getItem(this.STORAGE_KEY);
      return storedData ? JSON.parse(storedData) : [];
    },

    // 검색 기록을 `localStorage`에 저장하는 함수
    saveSearchHistory(data) {
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
    },

    // 중복 검사 후 검색 기록을 추가하는 함수
    addSearchHistory(newSearch) {
      // 현재 저장된 검색 기록 가져오기
      const searchHistory = this.getSearchHistory();

      // 중복 여부 확인
      const existingIndex = searchHistory.findIndex(
        (item) =>
          JSON.stringify(item.currentWorkInfo) ===
            JSON.stringify(newSearch.currentWorkInfo) &&
          JSON.stringify(item.hopeWorkInfo) ===
            JSON.stringify(newSearch.hopeWorkInfo)
      );

      if (existingIndex !== -1) {
        // 기존 데이터가 있으면 해당 항목 삭제
        searchHistory.splice(existingIndex, 1);
      }

      // 새 검색 데이터를 맨 앞에 추가
      searchHistory.unshift(newSearch);

      // 업데이트된 검색 기록을 `localStorage`에 저장
      this.saveSearchHistory(searchHistory);
    },
  },
};
</script>
