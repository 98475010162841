<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo
      :propTitle="`최근검색 목록`"
    ></top-back-header-dolligo>
    <header>
      <div class="content-wrap">
        <swiper
          class="swiper filter-swiper"
          :options="swiperOption"
          v-for="search in searchHistories"
          v-bind:key="search.id"
          @click="updateMainSearch(search)"
        >
          <swiper-slide v-if="search.currentWorkInfo.mb_8"
            ><a href="javascript:void(0)"
              >{{ search.currentWorkInfo.mb_8 }}(현재)</a
            ></swiper-slide
          >
          <swiper-slide v-if="search.currentWorkInfo.mb_17"
            ><a href="javascript:void(0)"
              >{{ search.currentWorkInfo.currentOfficeName }}(현재)</a
            ></swiper-slide
          >
          <swiper-slide v-if="search.currentWorkInfo.mb_9"
            ><a href="javascript:void(0)"
              >{{ search.currentWorkInfo.currentCityName }}(현재)</a
            ></swiper-slide
          >
          <swiper-slide v-if="search.currentWorkInfo.mb_10"
            ><a href="javascript:void(0)"
              >{{ search.currentWorkInfo.currentDistrictName }}(현재)</a
            ></swiper-slide
          >
          <swiper-slide v-if="search.hopeWorkInfo.mb_8"
            ><a href="javascript:void(0)"
              >{{ search.hopeWorkInfo.mb_8 }}(희망)</a
            ></swiper-slide
          >
          <swiper-slide v-if="search.hopeWorkInfo.hopeOfficeName"
            ><a href="javascript:void(0)"
              >{{ search.hopeWorkInfo.hopeOfficeName }}(희망)</a
            ></swiper-slide
          >
          <swiper-slide v-if="search.hopeWorkInfo.hopeCityName"
            ><a href="javascript:void(0)"
              >{{ search.hopeWorkInfo.hopeCityName }}(희망)</a
            >
          </swiper-slide>
          <swiper-slide v-if="search.hopeWorkInfo.hopeDistrict"
            ><a href="javascript:void(0)"
              >{{ search.hopeWorkInfo.hopeDistrictName }}(희망)</a
            ></swiper-slide
          >
          <swiper-slide v-if="search.hopeWorkInfo.mb_39"
            ><a href="javascript:void(0)">{{
              search.currentWorkInfo.timeJobName
            }}</a></swiper-slide
          >
          <swiper-slide v-if="search.currentWorkInfo.mb_1"
            ><a href="javascript:void(0)">{{
              search.currentWorkInfo.jobName
            }}</a></swiper-slide
          >
          <swiper-slide v-if="search.currentWorkInfo.mb_2"
            ><a href="javascript:void(0)">{{
              search.currentWorkInfo.rankName
            }}</a></swiper-slide
          >
          <swiper-slide v-if="search.currentWorkInfo.mb_27 == 1"
            ><a href="javascript:void(0)">일방전출</a></swiper-slide
          >
        </swiper>
      </div>
    </header>
  </div>
</template>

<script type="text/javascript">
import TopBackHeaderDolligo from "../../common/TopBackHeaderDolligo.vue";
import mixin from "../../../mixin";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  mixins: [mixin],
  components: {
    TopBackHeaderDolligo,
    Swiper,
    SwiperSlide,
  },
  name: "dolligoMainSearchRecent",
  data: function () {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 4,
        freeMode: true,
      },
      searchHistories: [],
      STORAGE_KEY: "searchHistory",
    };
  },
  async created() {
    this.searchHistories = await this.getSearchHistory();
    console.log(this.searchHistories);
  },
  methods: {
    // `localStorage`에서 검색 기록을 가져오는 함수
    async getSearchHistory() {
      const storedData = localStorage.getItem(this.STORAGE_KEY);
      const searchHistory = storedData ? JSON.parse(storedData) : [];

      // 검색 기록에 순차적인 숫자 ID 추가
      for (let index = 0; index < searchHistory.length; index++) {
        const item = searchHistory[index];

        // 기존 항목에 `id`가 없으면 순차적으로 추가
        if (!item.id) {
          item.id = index + 1; // 1부터 시작하는 ID 할당
        }

        // currentWorkInfo에 추가 데이터 할당
        if (item.currentWorkInfo.mb_9) {
          item.currentWorkInfo.currentCityName = await this.getCityName(
            item.currentWorkInfo.mb_9
          );
        }
        if (item.currentWorkInfo.mb_10) {
          item.currentWorkInfo.currentDistrictName = await this.getDistrictName(
            item.currentWorkInfo.mb_10
          );
        }
        if (item.currentWorkInfo.mb_17) {
          item.currentWorkInfo.currentOfficeName = await this.getOfficetName(
            item.currentWorkInfo.mb_17
          );
        }
        if (item.currentWorkInfo.mb_1) {
          item.currentWorkInfo.jobName = await this.getJobName(
            item.currentWorkInfo.mb_1
          );
        }
        if (item.currentWorkInfo.mb_2) {
          item.currentWorkInfo.rankName = await this.getRankName(
            item.currentWorkInfo.mb_2
          );
        }
        if (item.currentWorkInfo.mb_39) {
          item.currentWorkInfo.timeJobName = "시간선택제";
        }

        // hopeWorkInfo에 추가 데이터 할당
        if (item.hopeWorkInfo.mb_9) {
          item.hopeWorkInfo.hopeCityName = await this.getCityName(
            item.hopeWorkInfo.mb_9
          );
        }
        if (item.hopeWorkInfo.mb_10) {
          item.hopeWorkInfo.hopeDistrictName = await this.getDistrictName(
            item.hopeWorkInfo.mb_10
          );
        }
        if (item.hopeWorkInfo.mb_17) {
          item.hopeWorkInfo.hopeOfficeName = await this.getOfficetName(
            item.hopeWorkInfo.mb_17
          );
        }
      }

      // 업데이트된 검색 기록을 다시 저장
      // this.saveSearchHistory(searchHistory);

      return searchHistory;
    },

    updateMainSearch(newData) {
      let mainSearch = window.mainSearch;
      Object.keys(mainSearch).forEach((section) => {
        if (newData[section]) {
          Object.keys(mainSearch[section]).forEach((key) => {
            // 안전한 방식으로 hasOwnProperty 호출
            if (Object.prototype.hasOwnProperty.call(newData[section], key)) {
              mainSearch[section][key] = newData[section][key];
            }
          });
        }
      });
      this.$router.push({ name: "dolligoMainSearch" });
    },
  },
};
</script>

 