import ApplyList from '@/components/dolligo/main/ApplyList';
import Search from '@/components/dolligo/main/Search';
import DolligoMemberProfile from '@/components/dolligo/member/Profile';
import DolligoMemberMannerScore from '@/components/dolligo/member/MannerScore';
import DolligoMemberMannerScoreView from '@/components/dolligo/member/MannerScoreView';
import DolligoMemberRegistDetail from '@/components/dolligo/member/RegistDetail';
import DolligoMemberRegistDone from '@/components/dolligo/member/RegistDone';
import DolligoMainSearchRecent from '@/components/dolligo/main/SearchRecent';

const dolligoMain = [{
  path: '/dolligo/main',
  name: 'dolligoMain',
  component: ApplyList,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/main/search',
  name: 'dolligoMainSearch',
  component: Search,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/main/search/recent',
  name: 'dolligoMainSearchRecent',
  component: DolligoMainSearchRecent,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/member/:id',
  name: 'dolligoMemberProfile',
  component: DolligoMemberProfile,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/member/manner-score/:type',
  name: 'dolligoMemberMannerScore',
  component: DolligoMemberMannerScore,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/member/manner-score/view/sum',
  name: 'dolligoMemberMannerScoreView',
  component: DolligoMemberMannerScoreView,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/member/regist/detail',
  name: 'dolligoMemberRegistDetail',
  component: DolligoMemberRegistDetail,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/member/regist/done',
  name: 'dolligoMemberRegistDone',
  component: DolligoMemberRegistDone,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}]

export default dolligoMain